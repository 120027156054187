<template>
  <div class="row" id="client-location">
    <div class="col-md-12">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div
                    v-if="currentUser.u.is_service_provider && this.isNew"
                    class="col-md-12 mb-5"
                  >
                    <h5>Create Mobile Service for {{ client_name }}</h5>
                  </div>
                  <div class="col-md-3">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for=""
                          >Job title <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          class="form-control"
                          v-model="jobForm.title"
                          :class="errors['title'] ? 'border border-danger' : ''"
                        />
                        <span v-if="errors['title']" class="text-danger">{{
                          errors["title"][0]
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 pr-0" v-if="!currentUser.u.is_client" >
              <div class="form-group" >
                <label for="">Select Location <span class="text-danger">*</span></label>
                <select
                  v-model="client_id"
                  name=""
                  class="form-control"
                  id=""
                  @change="fetchOperationnByClient"
                >
                  <option value="all">Select Location</option>
                  <option
                    v-for="(citem, index) in getClients"
                    :key="index"
                    v-show="citem.is_mobile_fingerprinting"
                    :value="citem.id"
                  >
                    {{ citem.name }}
                  </option>
                </select>
              </div>
            </div>
                  <div class="col-3">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Select Service</label>
                        <select
                          class="form-control"
                          v-model="jobForm.service_id"
                          id=""
                        >
                          <option value="">Select Service</option>
                          <option
                            v-for="(service, index) in getBRateLists.rates"
                            :key="index"
                            :value="service.service.id"
                          >
                            {{ service.service.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Select Operator</label>
                        <select
                          class="form-control"
                          v-model="jobForm.operator_id"
                          id=""
                        >
                          <option value="">Select Operator</option>
                          <option
                            v-for="(user, index) in users"
                            :key="index"
                            :value="user.id"
                          >
                            {{ user.username }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Select Payment Method</label>
                        <select
                          class="form-control"
                          v-model="jobForm.payment_type"
                          id=""
                        >
                          <option value="">None</option>
                          <option value="Certified Check"
                            >Certified Check
                          </option>
                          <option value="Money Order">Money Order </option>
                          <option value="Credit Card Form"
                            >Credit Card Form
                          </option>
                          <option value="Cash"
                            >Cash
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-6">
                          <label for="scheduledDate">Scheduled Date</label>
                          <input
                            type="date"
                            class="form-control"
                            v-model="jobForm.scheduledDate"
                          />
                        </div>
                        <div class="col-6">
                          <label for="scheduledTime">Scheduled Time</label>
                          <input
                            type="time"
                            class="form-control"
                            v-model="jobForm.scheduledTime"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                  <div class="col-md-6">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="">Status</label>
                            <select
                              class="form-control"
                              v-model="jobForm.status"
                            >
                              <option value="Open">Open</option>
                              <option value="Closed">Closed</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group">
                            <label>Payment By</label>
                            <select
                              class="form-control"
                              v-model="jobForm.payment_by"
                            >
                              <option value="Individual">Individual</option>
                              <option value="Company">Company</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <legend><h6>Client Information</h6></legend>
                    <hr />
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">C/O </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="jobForm.care_of"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">ATTN </label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="jobForm.attn"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Name </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="jobForm.client.name"
                          @input="UpdateContact"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Phone Number </label>
                        <input
                          type="text"
                          class="form-control"
                          @input="UpdateContact"
                          v-mask="'(###)-###-####'"
                          v-model="jobForm.client.phone_number"
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Email</label>
                        <input
                          type="email"
                          class="form-control"
                          @input="UpdateContact"
                          v-model="jobForm.client.email"
                          :class="
                            errors['client_email'] ? 'border border-danger' : ''
                          "
                        />
                        <span
                          v-if="errors['client_email']"
                          class="text-danger"
                          >{{ errors["client_email"][0] }}</span
                        >
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Address Line 1</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.address"
                            :class="
                              errors['client_address']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_address']"
                            class="text-danger"
                            >{{ errors["client_address"][0] }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Address Line 2</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.address1"
                            :class="
                              errors['client_address1']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_address1']"
                            class="text-danger"
                            >{{ errors["client_address1"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">City</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.city"
                            :class="
                              errors['client_city']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_city']"
                            class="text-danger"
                            >{{ errors["client_city"][0] }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">State</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.state"
                            :class="
                              errors['client_state']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_state']"
                            class="text-danger"
                            >{{ errors["client_state"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Zip Code</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.zipcode"
                            :class="
                              errors['client_zipcode']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_zipcode']"
                            class="text-danger"
                            >{{ errors["client_zipcode"][0] }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Country</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.client.country"
                            :class="
                              errors['client_country']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_country']"
                            class="text-danger"
                            >{{ errors["client_country"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Address</label>
                        <keep-alive>
                          <GoogleMap
                            ref="clientmap"
                            @getFullAddress="setFullAddress"
                            @dragPointer="setLocation"
                            @onSearchClick="setLocation"
                          ></GoogleMap>
                        </keep-alive>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="col-md-12">
                      <legend><h6>Contact Person Information</h6></legend>
                      <hr />
                      <div class="row">
                        <div class="col-md-6 mt-5">
                          <span style="margin-left: 1rem;">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                              v-model="useClientDetail"
                              @change="updateContactDetail"
                            />
                            <label
                              class="form-check-label mt-4"
                              for="gridCheck1"
                            >
                              Use Client Detail
                            </label>
                          </span>
                        </div>
                        <div class="col-md-6 mt-5">
                          <span>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="gridCheck1"
                              v-model="jobForm.use_in_form"
                            />
                            <label
                              class="form-check-label mt-4"
                              for="gridCheck1"
                            >
                              Use in 1-783 form
                            </label>
                          </span>
                        </div>
                      </div>

                      <div class="col-md-12 mt-12">
                        <div class="form-check"></div>
                      </div>
                      <div class="form-group">
                        <label for="">Name </label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="jobForm.person.name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Phone Number</label>
                        <input
                          type="text"
                          class="form-control"
                          v-mask="'(###)-###-####'"
                          v-model="jobForm.person.contact_number"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Email </label>
                        <input
                          type="email"
                          class="form-control"
                          v-model="jobForm.person.email"
                          :class="
                            errors['contact_email']
                              ? 'border border-danger'
                              : ''
                          "
                        />
                        <span
                          v-if="errors['contact_email']"
                          class="text-danger"
                          >{{ errors["contact_email"][0] }}</span
                        >
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Address Line 1</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.address"
                            :class="
                              errors['client_address']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_address']"
                            class="text-danger"
                            >{{ errors["client_address"][0] }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Address Line 2</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.address1"
                            :class="
                              errors['client_address1']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_address1']"
                            class="text-danger"
                            >{{ errors["client_address1"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">City</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.city"
                            :class="
                              errors['client_city']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_city']"
                            class="text-danger"
                            >{{ errors["client_city"][0] }}</span
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">State</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.state"
                            :class="
                              errors['client_state']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_state']"
                            class="text-danger"
                            >{{ errors["client_state"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row m-1">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Zip Code</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.zipcode"
                            :class="
                              errors['client_zipcode']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_zipcode']"
                            class="text-danger"
                            >{{ errors["client_zipcode"][0] }}</span
                          >
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">Country</label>
                          <input
                            type="text"
                            class="form-control"
                            @input="UpdateContact"
                            v-model="jobForm.serviceLocation.country"
                            :class="
                              errors['client_country']
                                ? 'border border-danger'
                                : ''
                            "
                          />
                          <span
                            v-if="errors['client_country']"
                            class="text-danger"
                            >{{ errors["client_country"][0] }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Service Location</label>
                        <keep-alive>
                          <GoogleMap
                            ref="servicemap"
                            @getFullAddress="setServiceLocationAddress"
                            @dragPointer="setServiceLocation"
                            @onSearchClick="setServiceLocation"
                          ></GoogleMap>
                        </keep-alive>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="card-footer">
          <button
            v-if="!isNew"
            class="btn btn-success"
            @click="store('kt_update_job')"
            style="float: right"
            ref="kt_update_job"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="isNew"
            class="btn btn-success ml-3"
            style="float: right"
            ref="store_job_detail"
            @click="store('store_job_detail')"
          >
            <i class="fa fa-check"></i>Save
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  FETCH_B_LOCATION_RATE_LISTS,
  SAVE_CLIENT_JOB_DETAILS,
  FETCH_CLIENT_JOB_DETAILS,
  FETCH_B_USER,
  FETCH_CLIENTS,
  FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER
} from "@/core/services/store/actions.type";
import GoogleMap from "@/components/GoogleMap.vue";
// import { timeStamp } from "console";
export default {
  components: {
    GoogleMap
  },
  computed: {
    ...mapGetters(["getClients","getBRateLists", "currentUser", "getJobDetails"])
  },
  data() {
    return {
      client_id:'all',
      errors: [],
      useClientDetail: false,
      users: [],
      client_name: "",
      jobForm: {
        care_of: "",
        attn: "",
        payment_type: "",
        use_in_form: false,
        serviceLocation: {
          lat: "",
          lng: "",
          address: "",
          address1: "",
          city: "",
          country: "",
          state: "",
          zipcode: ""
        },
        title: "",
        status: "Closed",
        client: {
          name: "",
          phone_number: "",
          email: "",
          address: "",
          address1: "",
          lat: "",
          lng: "",
          city: "",
          country: "",
          state: "",
          zipcode: ""
        },
        person: {
          name: "",
          contact_number: "",
          email: ""
        },
        payment_by: "Individual",
        scheduledDate: "",
        scheduledTime: "",
        service_id: "",
        operator_id: ""
      },

      job_id: 0,
      client_slug: "",
      isNew: true,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Mobile Service",
          route: "client.job.list"
        },
        {
          id: 3,
          title: "Create New",
          route: ""
        }
      ]
    };
  },
  created() {
    this.$store.dispatch(FETCH_CLIENTS);
    this.client_slug = this.$route.params.client_slug;
    this.job_id = this.$route.params.job_id;
    var data = {
      client_slug: this.$route.params.client_slug,
      client_id: this.$route.params.client_id
    };
    if (this.currentUser.u.is_service_provider) {
      this.$store
        .dispatch(FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER, data)
        .then(data => {
          this.users = data.users;
          this.client_name = data.client.name;
        });
    } else {
      var _data = {
        is_client: this.currentUser.u.is_client,
        is_service_provider: this.currentUser.u.is_service_provider
      };
      this.$store.dispatch(FETCH_B_USER, _data).then(data => {
        this.users = data;
      });

    }

    if (this.job_id != 0) {
      this.fetchJobDetails();
    }
    // console.log(this.$route.params.client_id);
    if (this.job_id != 0) {
      this.breadCrumbs[2].title = "Edit";
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);

    this.$store.dispatch(
      FETCH_B_LOCATION_RATE_LISTS,
      this.$route.params.client_id,
    );

    // this.Search();
  },

  methods: {
    store(ref) {
      if (this.client_id == "all" && !this.currentUser.u.is_client) {
          this.$toastr.e("Please select a location to create new job");
        } 
      else{
      var client_id = this.client_id;
      var data = {
        title: this.jobForm.title,
        status: this.jobForm.status,
        use_in_form: this.jobForm.use_in_form,
        payment_type: this.jobForm.payment_type,
        service: this.jobForm.service_id,
        client_name: this.jobForm.client.name,
        client_address: this.jobForm.client.address,
        client_phone_no: this.jobForm.client.phone_number,
        client_email: this.jobForm.client.email,
        contact_person: this.jobForm.person.name,
        contact_email: this.jobForm.person.email,
        contact_phone_no: this.jobForm.person.contact_number,
        client_long: this.jobForm.client.lng,
        client_state: this.jobForm.client.state,
        client_city: this.jobForm.client.city,
        client_zipcode: this.jobForm.client.zipcode,
        client_country: this.jobForm.client.country,
        client_address1: this.jobForm.client.address1,
        client_lat: this.jobForm.client.lat,
        schedule_date: this.jobForm.scheduledDate,
        schedule_time: this.jobForm.scheduledTime,
        payment_by: this.jobForm.payment_by,
        city: this.jobForm.serviceLocation.city,
        state: this.jobForm.serviceLocation.state,
        country: this.jobForm.serviceLocation.country,
        zipcode: this.jobForm.serviceLocation.zipcode,
        care_of: this.jobForm.care_of,
        attn: this.jobForm.attn,
        service_location_address: this.jobForm.serviceLocation.address,
        service_location_address1: this.jobForm.serviceLocation.address1,
        service_location_lat: this.jobForm.serviceLocation.lat,
        service_location_lng: this.jobForm.serviceLocation.lng,
        id: parseInt(this.job_id),
        client_id: client_id,
        operator: parseInt(this.jobForm.operator_id)
      };

      this.openLoader(ref);

      this.$store
        .dispatch(SAVE_CLIENT_JOB_DETAILS, data)
        .then(data => {
          this.$toastr.s(data.msg);

          if (ref == "kt_update_job") {
            this.$router.push({
              name: "client.job.details",
              params: { client_slug: this.client_slug, job_id: this.job_id }
            });
            this.closeBtnLoad(ref);
          } else {
            this.$router.push({ name: "client.job.list" });
            this.closeBtnLoad(ref);
          }
        })
        .catch(error => {
          this.errors = error;
          this.closeBtnLoad(ref);
        });
      }
    },
    openLoader(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },

    goBack() {
      this.$router.push({
        name: "client.job.list"
      });
    },
    setLocationDragged(location) {
      this.jobForm.client.lng = location.lng;
      this.jobForm.client.lat = location.lat;
    },
    setLocation(location) {
      this.jobForm.client.lng = location.lng;
      this.jobForm.client.lat = location.lat;
      this.jobForm.client.city = location.city.long_name;
      this.jobForm.client.address = location.formatted_address;
      this.jobForm.client.zipcode = location.postal_code.long_name;
      this.jobForm.client.country = location.country.long_name;
      this.jobForm.client.state = location.state.long_name;
    },
    setFullAddress(data) {
      this.jobForm.client.address = data;
    },
    setServiceLocationDragged(location) {
      this.jobForm.serviceLocation.lng = location.lng;
      this.jobForm.serviceLocation.lat = location.lat;
      // this.jobForm.serviceLocation.city = location.city.long_name;
      // this.jobForm.serviceLocation.zipcode = location.zipcode.long_name;
      // this.jobForm.serviceLocation.country = location.country.long_name;
      // this.jobForm.serviceLocation.state = location.state.long_name;
    },
    setServiceLocation(location) {
      this.jobForm.serviceLocation.lng = location.lng;
      this.jobForm.serviceLocation.lat = location.lat;
      this.jobForm.serviceLocation.city = location.city.long_name;
      this.jobForm.serviceLocation.address = location.formatted_address;
      this.jobForm.serviceLocation.zipcode = location.postal_code.long_name;
      this.jobForm.serviceLocation.country = location.country.long_name;
      this.jobForm.serviceLocation.state = location.state.long_name;
    },
    setServiceLocationAddress(data) {
      this.jobForm.serviceLocation.address = data;
    },
    updateContactDetail(e) {
      if (e.target.checked) {
        this.jobForm.person.name = this.jobForm.client.name;
        this.jobForm.person.contact_number = this.jobForm.client.phone_number;
        this.jobForm.person.email = this.jobForm.client.email;
        this.jobForm.serviceLocation.lat = this.jobForm.client.lat;
        this.jobForm.serviceLocation.lng = this.jobForm.client.lng;
        this.jobForm.serviceLocation.address = this.jobForm.client.address;
        this.jobForm.serviceLocation.address1 = this.jobForm.client.address1;
        this.jobForm.serviceLocation.city = this.jobForm.client.city;
        this.jobForm.serviceLocation.zipcode = this.jobForm.client.zipcode;
        this.jobForm.serviceLocation.country = this.jobForm.client.country;
        this.jobForm.serviceLocation.state = this.jobForm.client.state;
        this.$refs.servicemap.defaultMarkerPosition(
          Number(this.jobForm.serviceLocation.lat),
          Number(this.jobForm.serviceLocation.lng)
        );
      }
    },
    UpdateContact() {
      if (this.useClientDetail) {
        this.jobForm.person.name = this.jobForm.client.name;
        this.jobForm.person.contact_number = this.jobForm.client.phone_number;
        this.jobForm.person.email = this.jobForm.client.email;
        this.jobForm.serviceLocation.address = this.jobForm.client.address;
        this.jobForm.serviceLocation.address1 = this.jobForm.client.address1;
        this.jobForm.serviceLocation.lat = this.jobForm.client.lat;
        this.jobForm.serviceLocation.lng = this.jobForm.client.lng;
        this.jobForm.serviceLocation.city = this.jobForm.client.city;
        this.jobForm.serviceLocation.zipcode = this.jobForm.client.zipcode;
        this.jobForm.serviceLocation.country = this.jobForm.client.country;
        this.jobForm.serviceLocation.state = this.jobForm.client.state;
        this.$refs.servicemap.defaultMarkerPosition(
          Number(this.jobForm.serviceLocation.lat),
          Number(this.jobForm.serviceLocation.lng)
        );
      }
    },
    fetchJobDetails() {
      this.isNew = false;
      this.$store
        .dispatch(FETCH_CLIENT_JOB_DETAILS, {
          id: parseInt(this.job_id),
          list: false
        })
        .then(async data => {
          // alert(data.client)
          this.client_id=data.data.client;
          await this.fetchOperationnByClient()

          var data_ = data.data;
          this.jobForm.title = data_.title;
          this.jobForm.status = data_.status;
          this.jobForm.service_id = data_.service;
          this.jobForm.use_in_form = data_.use_in_form;
          this.jobForm.care_of = data_.care_of;
          this.jobForm.attn = data_.attn;
          this.jobForm.payment_type = data_.payment_type;
          this.jobForm.scheduledDate = data_.schedule_date;
          this.jobForm.scheduledTime = data_.schedule_time;
          this.jobForm.person.name = data_.contact_person;
          this.jobForm.person.email = data_.contact_email;
          this.jobForm.person.contact_number = data_.contact_phone_no;
          this.jobForm.client.name = data_.client_name;
          this.jobForm.client.email = data_.client_email;
          this.jobForm.client.phone_number = data_.client_phone_no;
          this.jobForm.client.address = data_.client_address;
          this.jobForm.client.address1 = data_.client_address1;
          this.jobForm.client.zipcode = data_.client_zipcode;
          this.jobForm.client.country = data_.client_country;
          this.jobForm.client.state = data_.client_state;
          this.jobForm.client.city = data_.client_city;
          this.jobForm.client.lat = data_.client_lat;
          this.jobForm.client.lng = data_.client_long;
          this.jobForm.payment_by = data_.payment_by;
          this.jobForm.serviceLocation.lat = data_.service_location_lat;
          this.jobForm.serviceLocation.lng = data_.service_location_lng;
          this.jobForm.serviceLocation.city = data_.city;
          this.jobForm.serviceLocation.address = data_.service_location_address;
          this.jobForm.serviceLocation.address1 =
            data_.service_location_address1;
          this.jobForm.serviceLocation.zipcode = data_.zipcode;
          this.jobForm.serviceLocation.country = data_.country;
          this.jobForm.serviceLocation.state = data_.state;
          this.jobForm.operator_id = data_.operator;
          this.jobForm.serviceLocation.location =
            data_.service_location_address;
          this.$refs.clientmap.defaultMarkerPosition(
            Number(data_.client_lat),
            Number(data_.client_long)
          );
          this.$refs.servicemap.defaultMarkerPosition(
            Number(data_.service_location_lat),
            Number(data_.service_location_lng)
          );
         
        });
    },
    async fetchOperationnByClient(){
      await this.$store.dispatch(
      FETCH_B_LOCATION_RATE_LISTS,
      this.client_id,
    );
    
    this.client_slug = this.$route.params.client_slug;
    this.job_id = this.$route.params.job_id;
    var data = {
      client_slug: this.$route.params.client_slug,
      client_id: this.client_id
    };
    if (this.currentUser.u.is_service_provider) {
      await this.$store
        .dispatch(FETCH_B_LOCATION_AND_SERVICE_PROVIDER_USER, data)
        .then(data => {
          this.users = data.users;
          this.client_name = data.client.name;
        });
    } else {
      var _data = {
        is_client: this.currentUser.u.is_client,
        is_service_provider: this.currentUser.u.is_service_provider
      };
      await this.$store.dispatch(FETCH_B_USER, _data).then(data => {
        this.users = data;
      });
    }
    }
  }
};
</script>
